/* =========================================================================================
  File Name: vxTimeline.scss
  Description: Styles for vx-timeline component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

@import '../../variables/variables.scss';

.vx-timeline {
  margin-left: 1.5rem;
  padding-left: 43px;
  border-left: 2px solid #e5e8eb;

  .theme-dark & {
    border-color: $theme-dark-border-color;
  }

  li {
    position: relative;
    margin-bottom: 20px;

    .timeline-icon {
      position: absolute;
      top: 0;
      left: -4.3rem;
      padding: 0.75rem;
      padding-bottom: 0.4rem;
      border-radius: 50%;
    }

    .activity-desc {
      font-size: 0.9rem;
    }

    .activity-e-time {
      font-size: 0.8rem;
    }
  }
}
