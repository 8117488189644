/* =========================================================================================
    File Name: _variables.scss
    Description: partial- SCSS varibales
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== */

/* ========================================================
        SPACING
========================================================= */

$spacer: 2.2rem;
$spacer-sm: ($spacer / 2);
$spacer-lg: ($spacer * 2);

/* ========================================================
        COLORS
========================================================= */

$primary: #ea5455;
$secondary: #66999b;
$success: #28c76f;
$danger: #ea5455;
$warning: #ff9f43;
$dark: #1e1e1e;
$grey: #b8c2cc;
$grey-light: #dae1e7;
$white: #fff;
$black: #22292f;
$secondary: #66999b;

/* ========================================================
        TYPOGRAPHY
========================================================= */

$font-family-sans-serif: 'Poppins', 'Roboto' !default;
$font-family-serif: georgia, 'Times New Roman', times, serif !default;

$font-size-base: 16px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default;
$font-size-small: ceil(($font-size-base * 0.85)) !default;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.74;
$h3-font-size: $font-size-base * 1.51;
$h4-font-size: $font-size-base * 1.32;
$h5-font-size: $font-size-base * 1.14;
$h6-font-size: $font-size-base;

$line-height-base: 1.625rem !default;

$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 700 !default;
$headings-line-height: 1.2 !default;
$headings-color: #2c2c2c;
$headings-margin-bottom: ($spacer / 2) !default;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$content-color: #626262;
$theme-background: #f5f5f5;

/* ========================================================
        TYPOGRAPHY
========================================================= */

$reduced-sidebar-width: 80px;

/* ========================================================
        DARK THEME
========================================================= */

$sidebar-dark-bg: #10163a;
$content-dark-bg: #262c49;
$dark-card-color: #10163a;
$table-dark-stripe: #212744;
$table-light-stripe: #262c49;
$grid-dark-color: #343661;

$theme-light-dark-bg: #262c49;
$theme-dark-bg: #10163a;
$theme-dark-secondary-bg: #171e49;
$theme-dark-text-color: #c2c6dc;
$theme-dark-heading-color: #ebeefd;
// $theme-dark-heading-color: #C9CAD2;
$theme-dark-border-color: #414561;
$theme-dark-shadow-color: #0c112e;
$theme-dark-card-bg: #283046 !default;

$box-shadow: 0 4px 24px 0 rgba($black, 0.1) !default;
$card-border-radius: 0.428rem !default;
