<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import VxTimeline from '@/components/timeline/VxTimeline.vue';
import { Socket } from 'vue-socket.io-extended';
import { IMessage } from '../../../plugins/event-bus/notification/notification.constants';
import { mapGetters, mapState } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import { Loading } from '@/modules/auth/store/auth.service';
import { Notifications } from '@/store/modules/notification.module';

@Component({
  components: {
    StatisticsCardLine,
    VxTimeline,
    VuePerfectScrollbar,
  },
  computed: {
    ...mapGetters('notification', ['orderedMessages']),
  },
})
export default class NotificationTimeline extends Vue {
  orderedMessages!: IMessage[];
  @Loading
  public async deleteNotifications() {
    var b = await Notifications.deleteNotifications(tenantModule.tenant.id as string);
    Notifications.setMessage([]);
    console.log(this.orderedMessages);
  }
  @Socket() // --> listens to the event by method name, e.g. `connect`
  connect() {
    /*     console.log('sockets');
     */
  }
}
</script>

<template>
  <div class="w-full md:w-full px-3 mb-6 h-full">
    <VuePerfectScrollbar>
      <vx-card
        title="Notifications"
        class="p-4 dark:bg-gray-700 dark:text-white h-full card-wrapper overflow-y-auto border-solid border border-gray-200"
      >
        <portal to="deleteNotification">
          <button
            class="flex-end"
            v-tooltip="{ content: 'Clear notifications', container: 'button' }"
            v-if="orderedMessages.length"
            @click="deleteNotifications"
          >
            <feather-icon icon="TrashIcon"
          /></button>
        </portal>
        <vx-timeline :data="orderedMessages" class="py-4 mt-2 h-full card-wrapper"></vx-timeline>
      </vx-card>
    </VuePerfectScrollbar>
  </div>
</template>

<style scoped>
.card-wrapper {
  max-height: 698px;
}
</style>
